<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>

    <div class="page-content">
      <div v-if="activateBylawPopUp" class="popupCard">
        <div class="popupCardContent">
          <div class="popupCard-container">
            <div class="popupCard-header">
              <h1>
                {{ selectedInfraction.bylaw_number }} -
                {{ selectedInfraction.bylaw_title }}
              </h1>
              <button
                type="button"
                @click="closebylawInformation"
                class="del-btn delete-btn"
              >
                <i class="fa-solid fa-x"></i>
              </button>
            </div>

            <div class="popupCard-body">
              <p>{{ selectedInfraction.bylaw_description }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="report-card">
        <div class="card-header">
          <h1>New Report</h1>
          <div>
            <router-link :to="{ path: '/home' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <form class="report-container" @submit.prevent="submitReport">
          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Property</label>
              <label class="error" v-if="errorProperty">{{
                errorProperty
              }}</label>
            </div>

            <select name="properties" v-model="selectedProperty">
              <option
                v-for="property in properties"
                :key="property.id"
                :value="property.id"
              >
                {{ property.property }}
              </option>
            </select>
          </div>

          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Infraction</label>
              <label class="error" v-if="errorInfraction">{{
                errorInfraction
              }}</label>
              <button
                type="button"
                v-if="activatedQuestion"
                class="question-btn"
                @click="bylawInformation"
              >
                <i class="fa-solid fa-question"></i>
              </button>
            </div>

            <select
              @change="activateQ()"
              name="infractions"
              v-model="selectedInfraction"
            >
              <option
                v-for="infraction in infractions"
                :key="infraction.bylaw_title"
                :value="infraction"
              >
                {{
                  "[" + infraction.bylaw_number + "] " + infraction.bylaw_title
                }}
              </option>
            </select>
          </div>

          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Building & Unit #</label>
              <label class="error" v-if="errorBuildingUnit">{{
                errorBuildingUnit
              }}</label>
            </div>

            <div class="unit">
              <input
                name="Unit"
                placeholder="Building-Unit"
                v-model="buildingUnit"
                :disabled="isUnknown"
              />
              <div class="unit-check">
                <p style="margin-right: 0.5rem">Don't know?</p>
                <input type="checkbox" v-model="isUnknown" />
              </div>
            </div>
          </div>

          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Proposed Sanction</label>
              <label class="error" v-if="errorSanction">{{
                errorSanction
              }}</label>
            </div>

            <select name="sanctions" v-model="selectedSanction">
              <option>$50</option>
              <option>$100</option>
              <option>$150</option>
              <option>$200</option>
              <option>$250</option>
              <option>$300</option>
              <option>$350</option>
              <option>$400</option>
              <option>$450</option>
              <option>$500</option>
              <option>$550</option>
              <option>$600</option>
              <option>$650</option>
              <option>$700</option>
              <option>$750</option>
              <option>$800</option>
              <option>$850</option>
              <option>$900</option>
              <option>$950</option>
              <option>$1,000</option>
            </select>
          </div>

          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Date of Offense</label>
              <label class="error" v-if="errorOffenseDate">{{
                errorOffenseDate
              }}</label>
            </div>

            <input
              name="offenseDate"
              class="compliance-date"
              type="date"
              v-model="offenseDate"
            />
          </div>

          <div class="option">
            <div
              class="label-container"
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
              "
            >
              <label>Date of Compliance</label>
              <label class="error" v-if="errorComplianceDate">{{
                errorComplianceDate
              }}</label>
            </div>

            <input
              name="complianceDate"
              class="compliance-date"
              type="date"
              v-model="complianceDate"
            />
          </div>

          <div class="option">
            <label>Comments</label>
            <textarea
              name="comments"
              placeholder="Comments"
              v-model="comments"
            ></textarea>
          </div>

          <div>
            <input
              type="file"
              accept="image/*, video/*"
              @change="loadFile"
              multiple
              ref="fileInput"
              style="display: none"
            />
            <button
              type="button"
              @click="clickFileInput"
              class="file-upload-btn"
            >
              <i class="fa-solid fa-image" style="margin-right: 1rem"></i>
              Upload Photos
            </button>
            <label class="error" v-if="errorFiles">{{ errorFiles }}</label>
            <div
              class="img-container"
              :class="{ 'has-images': imageSrcs.length > 0 }"
            >
              <div
                v-for="(filePreview, index) in imageSrcs"
                :key="index"
                class="image-box"
              >
                <img
                  v-if="filePreview.type.startsWith('image')"
                  :src="filePreview.url"
                  :alt="'Image preview ' + index"
                />
                <video
                  v-else-if="filePreview.type.startsWith('video')"
                  controls
                  :src="filePreview.url"
                  class="video-preview"
                ></video>
                <button
                  type="button"
                  @click="removeImage(index)"
                  class="remove-image-btn"
                >
                  <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="submit-form">
            <button class="main-btn" :disabled="isSubmitting">
              Submit Report
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="isSubmitting" class="loading-overlay">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      properties: [],
      selectedProperty: null,
      property: "",
      infractions: [],
      selectedInfraction: null,
      isUnknown: false,
      buildingUnit: "",
      proposedSanction: "",
      selectedSanction: "$50",
      offenseDate: "",
      complianceDate: "",
      comments: "",
      files: [],
      imageSrcs: [],
      errorProperty: "",
      errorInfraction: "",
      errorBuildingUnit: "",
      errorComplianceDate: "",
      errorOffenseDate: "",
      errorFiles: "",
      errorSanction: "",
      isSubmitting: false,
      activatedQuestion: false,
      activateBylawPopUp: false,
    };
  },
  async mounted() {
    try {
      const response = await axios.get(`${this.domain}/api/properties`);
      const data = await response;
      this.properties = data.data;
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  },
  watch: {
    async selectedProperty(newSelectedPropertyId) {
      if (newSelectedPropertyId !== null) {
        try {
          const matchingProperty = this.properties.find(
            (p) => p.id === newSelectedPropertyId
          );

          this.property = matchingProperty ? matchingProperty.property : null;

          const response = await axios.get(
            `${this.domain}/api/bylaws/${newSelectedPropertyId}/`
          );
          const responseData = await response;
          this.infractions = responseData.data;
        } catch (error) {
          console.error("Error fetching infractions:", error);
          this.infractions = [];
        }
      } else {
        this.infractions = [];
      }
    },
    isUnknown(newVal) {
      if (newVal) {
        this.buildingUnit = ""; // Clear the input if checkbox is checked
      }
    },
  },
  methods: {
    async submitReport() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      this.errorProperty = "";
      this.errorInfraction = "";
      this.errorBuildingUnit = "";
      this.errorOffenseDate = "";
      this.errorComplianceDate = "";
      this.errorFiles = "";

      // Check if mandatory fields are filled
      if (!this.selectedProperty) {
        this.errorProperty = "Please select a property.";
      }

      if (!this.selectedInfraction) {
        this.errorInfraction = "Please select an infraction.";
      }

      if (!this.buildingUnit && !this.isUnknown) {
        this.errorBuildingUnit = "Please fill out field or check 'Don't know'.";
      }

      if (!this.complianceDate) {
        this.errorComplianceDate = "Please enter the Date of Compliance.";
      }

      if (!this.offenseDate) {
        this.errorOffenseDate = "Please enter the Date of Offense.";
      }

      if (!this.selectedSanction) {
        this.errorSanction = "Please select a Proposed Sanction.";
      }

      if (this.files.length === 0) {
        this.errorFiles = "Please upload at least one photo.";
      }

      // Check if any errors were set
      if (
        this.errorProperty ||
        this.errorInfraction ||
        this.errorBuildingUnit ||
        this.errorOffenseDate ||
        this.errorComplianceDate ||
        this.errorSanction ||
        this.errorFiles
      ) {
        this.isSubmitting = false;
        return; // Stop the function if there are errors
      }

      try {
        console.log(this.selectedSanction);
        const token = localStorage.getItem("userToken");

        console.log("meow");
        console.log(this.selectedInfraction.bylaw_title);

        // Fetch user email with userId
        const emailResponse = await axios.post(
          `${this.domain}/api/user/email`,
          {
            token,
          }
        );
        const userEmail = emailResponse.data.email; // Adjust this according to the actual response structure

        // Prepare form data for report submission
        const formData = new FormData();
        formData.append("property", this.property);
        formData.append("infraction", this.selectedInfraction.bylaw_title);
        formData.append(
          "buildingUnit",
          this.isUnknown ? null : this.buildingUnit
        );
        formData.append("offenseDate", this.offenseDate);
        formData.append("complianceDate", this.complianceDate);
        formData.append("sanction", this.selectedSanction);
        formData.append("comments", this.comments || null);
        formData.append("userEmail", userEmail); // Append user email to form data

        for (let file of this.files) {
          formData.append("files", file);
        }

        // Submit the report
        const submitResponse = await axios.post(
          `${this.domain}/api/report`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        const reportId = submitResponse.data.reportId;
        this.$router.push(`/report/${reportId}`);
      } catch (error) {
        console.error("Submission error:", error);
        this.isSubmitting = false;
      }
    },

    loadFile(event) {
      const selectedFiles = event.target.files;
      if (selectedFiles) {
        for (let i = 0; i < selectedFiles.length; i++) {
          const file = selectedFiles[i];
          const fileURL = URL.createObjectURL(file);
          // Push an object containing the URL and file type
          this.imageSrcs.push({
            url: fileURL,
            type: file.type, // This will be used to differentiate between image and video in the template
          });
          this.files.push(file); // Keep this for the actual file upload
        }
      }
    },

    removeImage(index) {
      URL.revokeObjectURL(this.imageSrcs[index].url); // Clean up the object URL
      this.imageSrcs.splice(index, 1);
      this.files.splice(index, 1); // Also remove the file from the upload list
    },

    clickFileInput() {
      this.$refs.fileInput.click(); // Trigger file input click
    },

    activateQ() {
      this.activatedQuestion = true;
      console.log(this.selectedInfraction);
    },

    bylawInformation() {
      this.activateBylawPopUp = true;
    },

    closebylawInformation() {
      this.activateBylawPopUp = false;
    },
  },
};
</script>

<style>
* {
  border: none;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  --csm-blue: #0081c3;
  transition: 0.2s;

  text-decoration: none;
}

label {
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0.25rem;
  margin-top: 1rem;
}

select {
  border: 1px solid black;
  padding: 0.25rem;
  font-size: 1rem;
}

option {
  font-size: 0.8rem;
}

input {
  padding: 0.25rem;
  border: 1px solid black;
  font-size: 1rem;
}

textarea {
  border: 1px solid black;
  padding: 0.5rem;
  resize: none;
  height: 5rem;
}

img {
  height: 5rem;
}

video {
  height: 5rem;
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.back-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-btn:first-child {
  margin-left: 1rem;
}

.report-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.option {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
}

.unit {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.unit-check {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.img-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  max-height: none;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;

  padding: 1rem;
}

.img-container.has-images {
  margin: 1rem 0rem 1rem 0rem;
  border: 0.2rem dashed black; /* Only applied when the container has images */
}

.image-box {
  position: relative;
  display: inline-block;
}

.remove-image-btn {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  border: none;
  background-color: transparent;
  color: rgb(255, 3, 3);
  cursor: pointer;
}

.file-upload-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: 0.2rem solid var(--csm-blue); /* Example border color */
  background-color: white;
  color: var(--csm-blue); /* Example text color */
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.25rem;
  text-decoration: none;
  width: 100%;
  margin-top: 1rem;
}

.file-upload-btn:hover {
  background: #00689c;
  border: 0.2rem solid #00689c;
  cursor: pointer;
  color: white;
}

.file-upload-btn:active {
  background-color: #0484c4;
  border: 0.2rem solid #0484c4;
  transition: 0.1s;
}

.file-upload-btn img {
  margin-right: 10px;
  height: 20px; /* Adjust as per your image */
}
.error {
  color: red;
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-overlay {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  right: 0;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.question-btn {
  background: rgb(0, 129, 195);
  padding: 0.45rem 0.5rem;
  color: white;
  border-radius: 0.25rem;
  width: 1.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.question-btn:hover {
  cursor: pointer;
  background: rgb(0, 121, 182);
}

.popupCard {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  right: 0;
}

.popupCardContent {
  background: white;
  color: black;
  font-size: 1.25rem;
  padding: 2rem;
  border-radius: 0.25rem;
  width: 40rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.popupCard-container {
  width: 100%;
}

.popupCard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.popupCard-header h1 {
  font-size: 1.85rem;
}

.popupCard-header button {
  width: 2.5rem;
  margin: 0;
}

.popupCard-header button:hover {
  cursor: pointer;
  background: red;
}

.popupCard-body {
  margin-top: 0.5rem;
  border-top: 1px solid black;
  padding-top: 0.5rem;
}

.popupCard-body p {
  text-align: justify;
}
</style>
