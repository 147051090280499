<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="details-card">
        <div class="card-header">
          <h1 v-if="!user">Altering User</h1>
          <h1 v-if="user">Altering User: ID {{ user[0].id }}</h1>
          <div>
            <router-link :to="{ path: '/admin/users/view' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <div v-if="user">
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Full Name:</strong>
                </td>
                <td>
                  <span>{{ user[0].name }}</span>
                  <button class="edit-btn">Edit</button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email Address:</strong>
                </td>
                <td>
                  <span>{{ user[0].email }}</span>
                  <button class="edit-btn">Edit</button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Property:</strong>
                </td>
                <td>
                  <span>{{ user[0].property }}</span>
                  <button class="edit-btn">Edit</button>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>User Role:</strong>
                </td>
                <td>
                  <select
                    v-model="userPermissions"
                    @change="updateRole"
                    :class="{ glowingBorder: roleSuccess }"
                  >
                    <option>Resident</option>
                    <option>Manager</option>
                    <option>Admin</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Verification Status:</strong>
                </td>
                <td>
                  <select
                    v-model="userVerification"
                    @change="updateVerification"
                    :class="{ glowingBorder: verifySuccess }"
                  >
                    <option>True</option>
                    <option>False</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Delete User</strong></td>
                <td>
                  <button class="deleteBtn">Delete User</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <p v-else>No user data available</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      user: null,
      userPermissions: "",
      userVerification: "",
      successfulRoute: "",
      roleSuccess: false,
      verifySuccess: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    console.log(this.id);
    this.fetchUserDetails(this.id);
  },
  methods: {
    fetchUserDetails(userId) {
      const userToken = localStorage.getItem("userToken");
      if (userToken && userId) {
        axios
          .post(`${this.domain}/api/users/details`, { userId, userToken })
          .then((response) => {
            this.user = response.data.user;
            this.userPermissions =
              this.user[0].level.charAt(0).toUpperCase() +
              this.user[0].level.slice(1);

            if (this.user[0].verified === 0) {
              this.userVerification = "False";
            } else {
              this.userVerification = "True";
            }
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
          });
      } else {
        console.error("No user token or report ID found");
      }
    },
    async updateRole() {
      const userToken = localStorage.getItem("userToken");
      const role = this.userPermissions.toLowerCase();
      const userId = this.user[0].id;

      axios
        .post(`${this.domain}/api/admin/users/update/role`, {
          userToken,
          role,
          userId,
        })
        .then((response) => {
          if (response.data.success === true) {
            this.roleSuccess = true;
            setTimeout(() => {
              this.roleSuccess = false;
            }, 500);
          }
        });
    },
    async updateVerification() {
      const userToken = localStorage.getItem("userToken");
      const userId = this.user[0].id;
      const userVerification = this.userVerification;
      const userEmail = this.user[0].email;
      const userName = this.user[0].name;

      axios
        .post(`${this.domain}/api/admin/users/update/verification`, {
          userToken,
          userId,
          userVerification,
          userEmail,
          userName,
        })
        .then((response) => {
          if (response.data.success === true) {
            this.verifySuccess = true;
            setTimeout(() => {
              this.verifySuccess = false;
            }, 500);
          }
        });
    },
    async submitUserChanges(userId) {
      const userToken = localStorage.getItem("userToken");
      const userVerification = this.userVerification;
      const userPermissions = this.userPermissions;
      const targetUserId = this.id;
      const originalVerification = this.user[0].verified;
      const userName = this.user[0].name;
      const userEmail = this.user[0].email;

      if (userToken && userId) {
        axios
          .post(`${this.domain}/api/users/update`, {
            userId,
            targetUserId,
            userVerification,
            userPermissions,
            originalVerification,
            userName,
            userEmail,
            userToken,
          })
          .then((response) => {
            console.log(response);
            if (response.status == 200) {
              this.successfulRoute = "Successfully Updated User";
            }
          })
          .catch((error) => {
            console.error("Error submitting:", error);
          });
      } else {
        console.error("No user token or user ID found.");
      }
    },
  },
};
</script>

<style>
.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 4px dashed black;
  padding: 1rem;
}

.images-header {
  margin: 1rem 0rem;
}

.user-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  font-size: 1.25rem;
  font-weight: 500;
  border: 0.1rem solid black;
  padding: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.success {
  color: green;
  font-weight: 800;
  text-align: center;
  font-size: 1.25rem;
}

.success-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.glowingBorder {
  outline: none;
  border-color: #05b805;
  box-shadow: 0 0 2px #05b805;
}

.deleteBtn {
  width: 100%;
  padding: 0.5rem;
  background: rgb(209, 33, 33);
  color: white;
  border-radius: 0.25rem;
}

.deleteBtn:hover {
  cursor: pointer;
}
</style>
