<template>
  <div v-if="tokenStatus.valid">
    <form @submit.prevent="resetPassword">
      <div class="root">
        <div class="login-card">
          <h1 class="login-h1">Change Password</h1>
          <div class="login-inputs">
            <input
              name="password"
              placeholder="Password"
              v-model="this.password"
              type="password"
              @input="validatePasswords"
            />
            <input
              name="password"
              placeholder="Confirm Password"
              v-model="this.confPassword"
              type="password"
              @input="validatePasswords"
            />
          </div>

          <div class="login-errors" style="text-align: center">
            <span class="login-error" v-if="passwordError">{{
              passwordError
            }}</span>
          </div>

          <button class="main-btn">Change Password</button>
        </div>
      </div>
    </form>
  </div>

  <div v-if="tokenStatus.expired">
    <div class="root">
      <h1 style="font-size: 2rem">Token is expired.</h1>
    </div>
  </div>

  <div v-if="!tokenStatus.expired && !tokenStatus.valid">
    <div class="root">
      <h1 style="font-size: 2rem">Token already utilized.</h1>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      token: this.$route.query.token,
      password: "",
      confPassword: "",
      passwordError: "",
      tokenStatus: {
        valid: false,
        expired: false,
        userId: null,
      },
      passwordChanged: null,
    };
  },
  mounted() {
    this.validateToken(this.token);
  },
  methods: {
    validatePasswords() {
      if (this.password === this.confPassword) {
        this.passwordError = "";
      } else {
        this.passwordError = "Passwords do not match";
      }
    },
    async validateToken() {
      if (this.token) {
        const token = this.token;
        axios
          .post(`${this.domain}/api/resetToken/validate`, { token })
          .then((response) => {
            this.tokenStatus = response.data;
          });
      }
    },
    async resetPassword() {
      if (this.password === this.confPassword) {
        const password = this.password;
        const userId = this.tokenStatus.userId;
        const token = this.token;
        axios
          .post(`${this.domain}/api/users/update/password`, {
            password,
            userId,
            token,
          })
          .then((response) => {
            console.log(response);
            this.passwordChanged = response.data.passwordChanged;

            if (this.passwordChanged) {
              this.$router.push("/login");
            }
          });
      }
    },
  },
};
</script>

<style>
.login-h1 {
  margin-bottom: 1rem;
  text-align: center;
}
</style>
