<template>
  <div class="exportHeader">
    <h1>Report Selection</h1>
    <div class="exitBtnContainer">
      <button @click="exitExport"><i class="fa-solid fa-x exitBtn"></i></button>
    </div>
  </div>
  <div class="tableContainer">
    <div class="tableContainerContainer"></div>
    <table v-if="data.length > 0">
      <tbody>
        <tr v-for="(item, index) in data" :key="index">
          <td>
            <input
              type="checkbox"
              v-model="item.checked"
              @change="updateCheckedItems"
              checked
            />
          </td>
          <td>{{ item.id }}</td>
          <td>{{ item.unit }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.infraction }}</td>
        </tr>
      </tbody>
    </table>
    <p class="expanded-text" v-else>No data for this time period</p>
  </div>
  <button v-if="data.length > 0" class="main-btn" @click="exportItems">
    Export
  </button>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    toExport: [],
  },
  mounted() {
    this.data.forEach((item) => {
      item.checked = true;
    });
    this.updateCheckedItems();
  },
  methods: {
    updateCheckedItems() {
      this.checkedItems = this.data.filter((item) => item.checked);
    },

    exportItems() {
      this.$emit("start-export", this.checkedItems);
      console.log(this.checkedItems);
    },

    exitExport() {
      console.log("Exiting export");
      this.$emit("close-export");
    },
  },
};
</script>

<style scoped>
.tableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 30rem;
}

table {
  background: white;
  margin: 0;
  width: 100%;
  table-layout: auto;
}

tr {
  flex-direction: row;
  width: 100%;
}

td {
  display: table-cell;
  padding: 0.5rem 1rem;
}

.exportHeader {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.exitBtn {
  background: rgb(209, 33, 33);
  color: white;
  padding: 0.5rem;
  border-radius: 0.25rem;
  line-height: 10px;
}

.exitBtnContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.exitBtn:hover {
  cursor: pointer;
  background: rgb(189, 8, 8);
}

.expanded-text {
  margin: 0rem 10rem 0rem 0rem;
  text-align: left;
}
</style>
