import axios from "axios";
import { createRouter, createWebHistory } from "vue-router";
import Register from "./views/RegisterForm.vue";
import Login from "./views/LoginPage.vue";
import AwaitVerification from "./views/await-verification.vue";
import Home from "./views/HomePage.vue";
import Report from "./views/NewReport.vue";
import Reports from "./views/PastReports.vue";
import ReportDetail from "./views/ReportDetail.vue";
import PasswordReset from "./views/PasswordReset.vue";
import Admin from "./views/AdminHomePage.vue";
import AdminNewProperty from "./views/AdminNewProperty.vue";
import AdminEditProperty from "./views/AdminEditProperty.vue";
import AdminViewProperty from "./views/AdminViewProperty.vue";
import AdminViewUsers from "./views/AdminViewUsers.vue";
import AdminViewUser from "./views/AdminViewUser.vue";
import AdminActions from "./views/AdminActionLog.vue";
import AdminReset from "./views/AdminResetPassword.vue";
import AdminViewingReport from "./views/AdminViewingReport.vue";
import AdminViewReports from "./views/AdminViewReports.vue";
import AdminViewReportsApproved from "./views/AdminViewReportsApproved.vue";
import AdminViewReport from "./views/AdminInfractions.vue";
import ManagerViewReports from "./views/ManagerViewReports.vue";
import ManagerViewingReport from "./views/ManagerViewingReport.vue";
import ManagerViewReportsApproved from "./views/ManagerViewReportsApproved";
import ManagerViewingReportApproved from "./views/ManagerViewingReportApproved.vue";

import TermsOfService from "./views/TermsOfService.vue";
import WebError403 from "./views/WebError403.vue";

const routes = [
  {
    path: "/",
    name: "/",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { requiresAuth: false },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { requiresAuth: false },
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
    meta: { requiresAuth: true },
  },
  {
    path: "/reports/bylaw",
    name: "Reports",
    component: Reports,
    meta: { requiresAuth: true },
  },
  {
    path: "/report/:id",
    name: ReportDetail,
    component: ReportDetail,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/property/:id",
    name: AdminViewProperty,
    component: AdminViewProperty,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/admin",
    name: Admin,
    component: Admin,
    meta: { requiresAuth: true },
  },
  {
    path: "/add-property",
    name: AdminNewProperty,
    component: AdminNewProperty,
    meta: { requiresAuth: true },
  },
  {
    path: "/edit-property",
    name: AdminEditProperty,
    component: AdminEditProperty,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/users/view",
    name: AdminViewUsers,
    component: AdminViewUsers,
    meta: { requiresAuth: true },
  },
  {
    path: "/admin/actions",
    name: AdminActions,
    component: AdminActions,
    meta: { requiresAuth: true },
  },
  {
    path: "/reset-password",
    name: AdminReset,
    component: AdminReset,
    meta: { requiresAuth: true },
  },
  {
    path: "/view-reports",
    name: AdminViewReports,
    component: AdminViewReports,
    meta: { requiresAuth: true },
  },
  {
    path: "/manager/view-reports",
    name: ManagerViewReports,
    component: ManagerViewReports,
    meta: { requiresAuth: true },
  },
  {
    path: "/view-reports/approved",
    name: AdminViewReportsApproved,
    component: AdminViewReportsApproved,
    meta: { requiresAuth: true },
  },
  {
    path: "/manager/view-reports/approved",
    name: ManagerViewReportsApproved,
    component: ManagerViewReportsApproved,
    meta: { requiresAuth: true },
  },
  {
    path: "/manager/unapproved/:id",
    name: ManagerViewingReport,
    component: ManagerViewingReport,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/manager/approved/:id",
    name: ManagerViewingReportApproved,
    component: ManagerViewingReportApproved,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/admin-view/:id",
    name: AdminViewReport,
    component: AdminViewReport,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/approved/:id",
    name: AdminViewingReport,
    component: AdminViewingReport,
    meta: { requiresAuth: true },
    props: true,
  },
  {
    path: "/user/:id",
    name: AdminViewUser,
    component: AdminViewUser,
    meta: { requresAuth: true },
    props: true,
  },
  {
    path: "/403",
    name: WebError403,
    component: WebError403,
    meta: { requiresAuth: false },
  },
  {
    path: "/await-verification",
    name: "AwaitVerification",
    component: AwaitVerification,
    meta: { requiresAuth: false },
  },
  {
    path: "/password-reset",
    name: "PasswordReset",
    component: PasswordReset,
    meta: { requiresAuth: false },
  },
  {
    path: "/tos",
    name: "TermsOfService",
    component: TermsOfService,
    meta: { requiresAuth: false },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const loggedIn = localStorage.getItem("userToken"); // Adjust based on how you store the token

  if (!requiresAuth) {
    next();
  } else if (!loggedIn) {
    next("/login");
  } else {
    axios
      .post("https://csenforcer.ca/api/validateToken", { token: loggedIn })
      .then((response) => {
        if (response.data.verification === "Unverified") {
          localStorage.setItem("userToken", response.data.token);
          next("/await-verification");
        } else if (response.data.verification === "Verified") {
          localStorage.setItem("userToken", response.data.token);
          next();
        }
      })
      .catch((error) => {
        console.error("Error during route guard token validation:", error);
        next("/login");
      });
  }
});

export default router;
