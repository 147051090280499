<template>
  <div class="verif-container">
    <div class="verification">
      <h1>Successfully Registered</h1>
      <p>We will send you an email when your account has been verified</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
    };
  },
  mounted() {
    this.checkStatus();
  },
  methods: {
    checkStatus() {
      const token = localStorage.getItem("userToken");
      if (token) {
        axios
          .post(`${this.domain}/api/validateToken`, { token })
          .then((response) => {
            console.log(response);
            console.log(response.data.verification);
            if (response.data.verification === "Unverified") {
              return;
            } else if (response.data.verification === "Verified") {
              localStorage.setItem("userToken", response.data.token);
              this.$router.push("/home");
            }
          })
          .catch((error) => {
            console.error("Error during route guard token validation:", error);
            this.$router.push("/login");
          });
      }
    },
  },
};
</script>

<style>
.verification {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
</style>
