<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="content-container">
      <div class="header">
        <div class="header-container">
          <div class="logo">
            <img class="logo-img" src="../assets/logo.png" />
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="page-card">
          <div class="card-header">
            <h1>Add New Property</h1>
            <div>
              <router-link :to="{ path: '/admin' }">
                <a class="back-btn">
                  <i class="fa-solid fa-arrow-left"></i>
                  <p style="margin-left: 0.25rem">Back</p>
                </a>
              </router-link>
            </div>
          </div>

          <form @submit.prevent="submitProperty">
            <div class="add-property-content">
              <label>Property</label><br />
              <input v-model="property" placeholder="Property Name" />
            </div>

            <div class="add-property-bylaws">
              <div class="bylaw-input-container">
                <input
                  v-model="newBylaw"
                  placeholder="Bylaw"
                  class="bylaw-input"
                />
                <button type="button" @click="addBylaw" class="bylaw-input-btn">
                  Add
                </button>
              </div>
            </div>

            <div class="add-property-bylaws-list">
              <table>
                <thead>
                  <tr>
                    <th>Bylaws</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(bylaw, index) in bylaws" :key="index">
                    <td>{{ bylaw }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button class="main-btn">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      newBylaw: "", // Model for the input field // Array to store the bylaws
      bylaws: [],
      property: "",
    };
  },
  methods: {
    addBylaw() {
      if (this.newBylaw.trim() !== "") {
        this.bylaws.push(this.newBylaw.trim()); // Add the bylaw to the list
        this.newBylaw = ""; // Clear the input field
      }
    },
    async submitProperty() {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        const dataToSend = {
          token: userToken,
          property: this.property,
          bylaws: this.bylaws,
        };

        axios
          .post(`${this.domain}/api/submitProperty`, dataToSend)
          .then((response) => {
            console.log(response.data.id);
            this.$router.push(`/property/${response.data.id}`);
          })
          .catch((error) => {
            console.error("Error fetching reports:", error);
          });
      } else {
        console.error("No user token found");
        // Handle the absence of token, e.g., redirect to login
      }
    },
  },
};
</script>

<style>
.add-property-content {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.bylaw-input {
  width: 100%;
}

.add-property-bylaws-list {
  font-size: 1rem;
}

.welcome h1 {
  text-align: left;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

th {
  background-color: #f2f2f2;
}

.view-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.view-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.option-btn {
  margin-top: 1rem;
  padding: 1rem 0rem;
  width: 100%;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  background: var(--csm-blue);
}

.option-btn:first-child {
  border-radius: 1rem 0rem 0rem 1rem;
}

.option-btn:last-child {
  border-radius: 0rem 1rem 1rem 0rem;
}

.option-btn:hover {
  background: var(--csm-blue-hover);
  cursor: pointer;
}

/* .option-btn:active{
    background-color: var(--csm-blue-active);
    transition: 0.1s;
} */

.active {
  background: rgb(14, 103, 155);
}
</style>
