<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="details-card">
        <div class="card-header">
          <div style="display: flex; flex-direction: row; align-items: center">
            <h1>Report {{ this.id }}</h1>
          </div>
          <div>
            <router-link :to="{ path: '/view-reports/approved' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <div v-if="report">
          <table border="1">
            <tr>
              <td>
                <strong>Property:</strong>
              </td>
              <td>
                {{ report.property }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Infraction:</strong>
              </td>
              <td>
                {{ report.infraction }}
              </td>
            </tr>

            <tr>
              <td>
                <strong>Unit:</strong>
              </td>
              <td>
                {{ report.unit === "null" ? "Unknown" : report.unit }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Offense Date:</strong>
              </td>
              <td>
                {{ report.date }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Proposed Sanction:</strong>
              </td>
              <td>
                {{ report.sanction }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Compliance Date:</strong>
              </td>
              <td>
                {{ report.compliance }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Comments:</strong>
              </td>
              <td>
                {{ report.comments === "null" ? "None" : report.comments }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Reporter Email:</strong>
              </td>
              <td>
                {{ report.email }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Unapprove Report</strong>
              </td>
              <td>
                <button class="approve-btn" @click="unapproveReport(this.id)">
                  Unapprove Report
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="images-header">
          <h3>Images</h3>
        </div>

        <div class="images-container" v-if="images.length">
          <template v-for="mediaItem in images" :key="mediaItem">
            <img
              v-if="isImage(mediaItem)"
              :src="mediaItem"
              alt="Report Image"
              class="report-media"
            />
            <video
              v-else-if="isVideo(mediaItem)"
              controls
              class="report-media"
              :src="mediaItem"
              :type="getVideoMimeType(mediaItem)"
            >
              Your browser does not support the video tag.
            </video>
          </template>
        </div>

        <p v-else>No report data available</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      report: null,
      images: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchReportDetails(this.id);
  },
  methods: {
    fetchReportDetails(reportId) {
      const userToken = localStorage.getItem("userToken");
      if (userToken && reportId) {
        axios
          .post(`${this.domain}/api/admin/reportDetails`, {
            reportId,
            userToken,
          })
          .then((response) => {
            this.report = response.data.report;
            this.images = response.data.images;
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
          });
      } else {
        console.error("No user token or report ID found");
      }
    },
    isImage(mediaItem) {
      return /\.(jpg|jpeg|png|gif)$/i.test(mediaItem);
    },
    isVideo(mediaItem) {
      return /\.(mp4|webm|ogg|avi|mov|mkv|flv|m4v|wmv)$/i.test(mediaItem);
    },

    getVideoMimeType(url) {
      if (/\.mp4$/i.test(url)) return "video/mp4";
      if (/\.webm$/i.test(url)) return "video/webm";
      if (/\.ogg$/i.test(url)) return "video/ogg";
      if (/\.avi$/i.test(url)) return "video/x-msvideo";
      if (/\.mov$/i.test(url)) return "video/quicktime";
      if (/\.mkv$/i.test(url)) return "video/x-matroska";
      if (/\.flv$/i.test(url)) return "video/x-flv";
      if (/\.m4v$/i.test(url)) return "video/x-m4v";
      if (/\.wmv$/i.test(url)) return "video/x-ms-wmv";
      return "application/octet-stream"; // Generic binary stream MIME type for unknown video formats
    },
    unapproveReport(reportId) {
      const userToken = localStorage.getItem("userToken");
      axios
        .post(`${this.domain}/api/admin/report/unapprove`, {
          userToken,
          reportId,
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$router.push(`/admin-view/${reportId}`);
          }
        });
    },
  },
};
</script>

<style>
.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 4px dashed black;
  padding: 1rem;
}

.images-header {
  margin: 1rem 0rem;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.edit-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.plus-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.25rem 0.3rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.plus-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.plus-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}
.commentArea {
  width: 100%;
}

select {
  width: 100%;
}

.offender-email {
  width: 75%;
}

.approve-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
}

.approve-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.approve-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}
</style>
