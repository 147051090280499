<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="details-card">
        <div class="card-header">
          <h1>Alter Property</h1>
          <div>
            <router-link :to="{ path: '/edit-property' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <div v-if="property">
          <form @submit.prevent="submitUserChanges">
            <table>
              <tbody>
                <h2 class="tableHeader1">Property</h2>
                <tr v-for="property in property" :key="property.id">
                  <td>
                    <span v-if="!isEditingProperty(property.id)">{{
                      property.property
                    }}</span>
                    <input
                      v-else
                      type="text"
                      v-model="property.editableProperty"
                    />

                    <button @click="toggleEdit(property)" class="view-btn">
                      {{ isEditingProperty(property.id) ? "Submit" : "Edit" }}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

            <button
              type="button"
              @click="deleteProperty(this.id)"
              class="del-btn main-delete-btn"
            >
              Delete Property
            </button>

            <div class="add-property-manager">
              <h2 class="tableHeader1">Property Manager</h2>
              <select
                name="managers"
                v-model="selectedManager"
                @change="changeManager(selectedManager)"
              >
                <option
                  v-for="manager in managers"
                  :key="manager.id"
                  :value="manager.name"
                >
                  {{ manager.name }}
                </option>
              </select>
            </div>

            <div class="add-property-bylaws" style="margin-top: 2rem">
              <h2 class="tableHeader1">New Bylaw</h2>
              <div class="bylaw-input-container">
                <div class="bylaw-input-top">
                  <input
                    class="bylawInputSmall"
                    v-model="bylawNumber"
                    placeholder="1.0"
                  />
                  <input
                    v-model="newBylaw"
                    placeholder="Bylaw Header"
                    class="bylaw-input"
                  />
                </div>
                <div class="bylaw-input-bottom">
                  <textarea
                    v-model="bylawDescription"
                    class="bylaw-textarea"
                    placeholder="Bylaw Description"
                  ></textarea>
                </div>
                <button type="button" @click="addBylaw" class="bylaw-input-btn">
                  Add Bylaw
                </button>
              </div>
            </div>

            <table>
              <tbody>
                <h2 class="tableHeader1">Bylaws</h2>
                <tr v-for="bylaw in bylaws" :key="bylaw.id">
                  <td>
                    <div class="bylaw-table-container">
                      <div class="bylaw-table-top">
                        <span
                          class="bylaw-number"
                          v-if="!isEditingBylaw(bylaw.id)"
                          >{{ bylaw.bylaw_number }}</span
                        >

                        <input
                          v-else
                          type="text"
                          v-model="newBylawNumber"
                          class="bylawInputSmall"
                        />

                        <span v-if="!isEditingBylaw(bylaw.id)">{{
                          bylaw.bylaw_title
                        }}</span>

                        <input
                          v-else
                          type="text"
                          v-model="bylaw.editableBylaw"
                          class="bylaw-input"
                        />
                      </div>

                      <div class="bylaw-table-bottom">
                        <span v-if="!isEditingBylaw(bylaw.id)">{{
                          bylaw.bylaw_description
                        }}</span>
                        <textarea
                          v-model="bylawDesc"
                          class="bylaw-textarea"
                          v-else
                        ></textarea>
                        <div class="edit-delete">
                          <button
                            @click="toggleEditBylaw(bylaw)"
                            class="view-btn"
                          >
                            {{ isEditingBylaw(bylaw.id) ? "Submit" : "Edit" }}
                          </button>
                          <button
                            v-if="!isEditingBylaw(bylaw.id)"
                            @click="deleteBylaw(bylaw.id)"
                            class="view-btn delete-btn"
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <p v-else>No property data available</p>
      </div>
    </div>
  </div>

  <div class="pop-up" v-if="deletingProperty">
    <div class="pop-up-card" v-if="warningPopUp">
      <p>Are you sure you want to delete this property?</p>
      <div class="pop-up-card-btn">
        <button class="main-delete-btn" @click="approveDelete">Yes</button>
        <button class="pop-up-card-no" @click="disableDelete">No</button>
      </div>
    </div>
    <div class="pop-up-card" v-if="successPopUp">
      <p>Property & Associated Bylaws Successfully Deleted!</p>
      <div class="pop-up-card-btn">
        <button class="pop-up-card-no" @click="closePopUps">OK!</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      property: null,
      bylaws: [],
      managers: [],
      successfulRoute: "",
      currentlyEditing: null,
      newBylawNumber: "",
      bylawDesc: "",
      newBylaw: "",
      bylawNumber: "",
      bylawDescription: "",
      deletingProperty: false,
      warningPopUp: false,
      successPopUp: false,
      selectedManager: "",
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    console.log(this.id);
    this.fetchPropertyManagers();
  },
  methods: {
    closePopUps() {
      this.deletingProperty = false;
      this.warningPopUp = false;
      this.successPopUp = false;
      this.$router.push(`/edit-property`);
    },
    disableDelete() {
      this.deletingProperty = false;
      this.warningPopUp = false;
    },
    deleteProperty() {
      this.deletingProperty = true;
      this.warningPopUp = true;
    },
    approveDelete() {
      const propertyId = this.id;
      const userToken = localStorage.getItem("userToken");
      axios
        .post(`${this.domain}/api/property/delete`, {
          propertyId,
          userToken,
        })
        .then((response) => {
          if (response.data.success == true) {
            this.warningPopUp = false;
            this.successPopUp = true;
          }
          console.log(response);
        });
    },
    addBylaw() {
      const userToken = localStorage.getItem("userToken");
      const propertyId = this.id;
      const newBylaw = this.newBylaw;
      const bylawNumber = this.bylawNumber;
      const bylawDescription = this.bylawDescription;

      if (this.newBylaw.trim() !== "") {
        axios
          .post(`${this.domain}/api/admin/bylaws/add`, {
            propertyId,
            userToken,
            newBylaw,
            bylawNumber,
            bylawDescription,
          })
          .then((response) => {
            console.log(response.data);
            console.log(this.bylaws);
            const json = {
              propertyId: response.data.propertyId,
              bylaw_title: response.data.bylaw_title,
              bylaw_number: response.data.bylaw_number,
              bylaw_description: response.data.bylaw_description,
              id: response.data.insertId,
            };

            console.log(json);

            this.bylaws.push(json);
          });

        this.newBylaw = "";
        this.bylawDescription = "";
        this.bylawNumber = "";
      }
    },
    changeManager() {
      const propertyId = this.id;
      const userToken = localStorage.getItem("userToken");
      const manager = this.managers.find(
        (manager) => manager.name === this.selectedManager
      );
      const managerId = manager.id;

      axios
        .post(`${this.domain}/api/property/manager/update`, {
          userToken,
          managerId,
          propertyId,
        })
        .then((response) => {
          if (response.data.success == true) {
            // initiate a pop up for a short quantity of time then make it go awway lo lo lo  l o l o l o l o l ol ol
          }
        });
    },
    fetchPropertyManagers() {
      console.log("Meow");
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        axios
          .post(`${this.domain}/api/users/managers`, {
            userToken,
          })
          .then((response) => {
            this.managers = response.data.managers;
            console.log(this.managers);
            this.fetchPropertyDetails(this.id);
          });
      }
    },
    fetchPropertyDetails(propertyId) {
      const userToken = localStorage.getItem("userToken");
      if (userToken && propertyId) {
        axios
          .post(`${this.domain}/api/property/details`, {
            propertyId,
            userToken,
          })
          .then((response) => {
            this.property = response.data.property;
            this.bylaws = response.data.bylaws;
            const managerId = this.property[0].manager_id;
            console.log(managerId);

            if (managerId == null) {
              console.log("this is null");
            } else if (managerId != null) {
              const manager = this.managers.find(
                (manager) => manager.id === managerId
              );
              this.selectedManager = manager.name;
              console.log(this.selectedManager);
            }
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
          });
      } else {
        console.error("No user token or report ID found");
      }
    },
    deleteBylaw(bylawId) {
      const userToken = localStorage.getItem("userToken");

      if (bylawId && userToken) {
        axios
          .post(`${this.domain}/api/bylaw/delete`, { bylawId, userToken })
          .then((response) => {
            console.log(response);
            this.bylaws = this.bylaws.filter((bylaws) => bylaws.id !== bylawId);
          })
          .catch((error) => {
            console.error("Error deleting bylaw: ", error);
          });
      } else {
        console.error("No user token or bylaw id found.");
      }
    },
    isEditingProperty(propertyId) {
      return this.currentlyEditing === propertyId;
    },
    toggleEdit(property) {
      if (this.currentlyEditing === property.id) {
        const userToken = localStorage.getItem("userToken");
        const propertyId = property.id;
        const newName = property.editableProperty;

        axios
          .post(`${this.domain}/api/property/update`, {
            newName,
            propertyId,
            userToken,
          })
          .then((response) => {
            console.log(response);
            property.property = property.editableProperty;
          })
          .catch((error) => {
            // Handle any errors here
            console.error("There was an error updating the property:", error);
          })
          .finally(() => {
            // Reset the editing state regardless of success or error
            this.currentlyEditing = null;
          });
      } else {
        // Start editing by setting up the editableProperty and tracking the currently editing ID
        property.editableProperty = property.property;
        this.currentlyEditing = property.id;
      }
    },
    isEditingBylaw(bylawId) {
      return this.currentlyEditing === bylawId;
    },

    toggleEditBylaw(bylaw) {
      console.log(bylaw);
      if (this.isEditingBylaw(bylaw.id)) {
        this.submitBylawEdit(bylaw);
      } else {
        bylaw.editableBylaw = bylaw.bylaw_title;
        this.newBylawNumber = bylaw.bylaw_number;
        this.bylawDesc = bylaw.bylaw_description;
        this.currentlyEditing = bylaw.id;

        console.log(this.newBylawNumber);
      }
    },

    submitBylawEdit(bylaw) {
      const userToken = localStorage.getItem("userToken");
      const bylawId = bylaw.id;
      const newName = bylaw.editableBylaw;
      const bylawNumber = this.newBylawNumber;
      const bylawDesc = this.bylawDesc;

      axios
        .post(`${this.domain}/api/admin/bylaws/update`, {
          bylawId,
          newName,
          bylawNumber,
          bylawDesc,
          userToken,
        })
        .then((response) => {
          console.log(response);
          bylaw.bylaw_title = newName;
          bylaw.bylaw_number = bylawNumber;
          bylaw.bylaw_description = bylawDesc;
        })
        .catch((error) => {
          console.error("There was an error updating the bylaw:", error);
        })
        .finally(() => {
          this.currentlyEditing = null;
        });
    },
  },
};
</script>

<style>
.delete-btn {
  margin-left: 0.5rem;
  background: rgb(209, 33, 33) !important;
}

.main-delete-btn {
  background: rgb(209, 33, 33) !important;
  color: white;
}

.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 4px dashed black;
  padding: 1rem;
}

.images-header {
  margin: 1rem 0rem;
}

.user-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
  font-size: 1.25rem;
  font-weight: 500;
  border: 0.1rem solid black;
  padding: 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.success {
  color: green;
  font-weight: 800;
  text-align: center;
  font-size: 1.25rem;
}

.success-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.pop-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  min-height: 100vh;
  min-width: 100vw;
}

.pop-up-card {
  width: 25rem;
  /* height: 15rem; */
  background: white;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.pop-up-card p {
  margin: 0.5rem;
}

.pop-up-card-btn button {
  margin: 1rem;
  padding: 1rem;
  width: 10rem;
  border-radius: 0.25rem;
}

.pop-up-card-btn button:hover {
  cursor: pointer;
}

.pop-up-card-no {
  background: var(--csm-blue);
  color: white;
}

.add-property-manager {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.add-property-bylaws {
  border: 1px solid black;
  border-radius: 0.25rem;
  padding: 1rem;
}

.bylaw-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bylaw-input-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.bylaw-input-bottom {
  display: flex;
  flex-direction: columns;
  align-items: center;
  width: 100%;
}

.bylawInputSmall {
  width: 2rem;
  text-align: center;
  margin-right: 0.75rem;
}

.blyaw-input {
  width: 100%;
}

.bylaw-textarea {
  width: -webkit-fill-available;
  margin-top: 0.75rem;
}

.bylaw-input-btn {
  width: 100%;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  background: var(--csm-blue);
  height: 1.75rem;
  margin-top: 0.75rem;
}

.bylaw-input-btn:hover {
  background: var(--csm-blue-hover);
  cursor: pointer;
}

.bylaw-input-btn:active {
  background-color: var(--csm-blue-active);
  transition: 0.1s;
}

.tableHeader1 {
  margin-bottom: 0.25rem;
}

.bylaw-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.bylaw-table-top {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.25rem;
  border-bottom: 2px solid black;
  padding: 0.25rem;
}

.bylaw-number {
  margin-right: 0.5rem;
  font-weight: 600;
}

.edit-delete {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.75rem;
}

.edit-delete button {
  width: 100%;
  margin-left: 0;
}

.edit-delete button:first-child {
  margin-bottom: 0.75rem;
}
</style>
