<template>
  <div class="root">
    <div class="login-card">
      <div class="logo-container">
        <img src="../assets/logo.png" alt="CS Enforcer Logo" />
      </div>

      <form class="login-card-container" @submit.prevent="register">
        <div class="login-inputs">
          <input placeholder="Name" v-model="user.name" />
          <input
            placeholder="Email"
            v-model="user.email"
            @input="validateEmail"
          />
          <input
            type="password"
            placeholder="Password"
            v-model="user.password"
            @input="validatePasswords"
          />
          <input
            type="password"
            placeholder="Confirm Password"
            v-model="user.confpassword"
            @input="validatePasswords"
          />
        </div>

        <div class="login-errors">
          <span v-if="emailError" class="error">{{ emailError }}</span
          ><br />
          <span v-if="passwordError" class="error">{{ passwordError }}</span
          ><br />
          <span v-if="registerError" class="error">{{ registerError }}</span>
        </div>

        <button class="main-btn">Register</button>
        <router-link :to="{ path: '/login' }"
          ><p class="hyperlink">
            <i>Already registered? Login!</i>
          </p></router-link
        >
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      user: {
        name: "",
        email: "",
        property: "hillside estates",
        password: "",
        confpassword: "",
      },
      emailError: "",
      passwordError: "",
      registerError: "",
    };
  },
  methods: {
    validateEmail() {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      if (this.user.email.match(regex)) {
        this.emailError = "";
      } else {
        this.emailError = "Invalid email format";
      }
    },
    validatePasswords() {
      if (this.user.password === this.user.confpassword) {
        this.passwordError = "";
      } else {
        this.passwordError = "Passwords do not match";
      }
    },

    async register() {
      if (!this.emailError && this.user.email) {
        try {
          const response = await axios.post(
            `${this.domain}/api/register`,
            this.user
          );

          if (response.data == "Email is already registered.") {
            this.registerError = "Email is already registered.";
          } else {
            localStorage.setItem("userToken", response.data.token);
            this.$router.push("/await-verification");
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
  },
};
</script>

<style></style>
