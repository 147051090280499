<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="error-content">
    <div class="error-text">
      <div class="error-numb">
        <h1>Error 403</h1>
        <i class="fa-solid fa-lock error-icon"></i>
      </div>
      <p>You are not allowed to view this content.</p>
    </div>
  </div>
</template>

<script></script>

<style>
.error-content {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
}

.error-numb {
  display: flex;
  align-items: center;
}

.error-icon {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  color: rgb(218, 19, 19);
}
</style>
