<template>
  <div class="actionLogContainer">
    <div class="actionsCard">
      <div class="actionsCardHeader">
        <h1>Action Log</h1>
        <input />
      </div>

      <div class="actionsCardContent">
        <table class="actiontable">
          <thead>
            <th>Id</th>
            <th>Action</th>
            <th>Actor</th>
            <th>Date</th>
            <th>Affected Item</th>
            <th>Original State</th>
            <th>New State</th>
          </thead>
          <tr>
            <td>1</td>
            <td>Report Approval</td>
            <td>Jayden Verstraten</td>
            <td>04-04-2024 - 12:30 PM</td>
            <td>Report ID: 20</td>
            <td>Unapproved</td>
            <td>Approved</td>
          </tr>
          <tr>
            <td>2</td>
            <td>User Approval</td>
            <td>Braden Siracky</td>
            <td>04-04-2024 - 12:43 PM</td>
            <td>User ID: 35</td>
            <td>Unapproved</td>
            <td>Approved</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Compliance Email</td>
            <td>Jayden Verstraten</td>
            <td>04-05-2024 - 12:43 PM</td>
            <td>Report ID: 20</td>
            <td>Unsent</td>
            <td>Mailed To: xx@gmail.com</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script></script>

<style>
.actiontable table {
  width: 100%;
}
.actiontable th,
.actiontable td {
  width: 100%;
}

.actiontable tr {
  display: flex;
}

.actionLogContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.actionsCard {
  padding: 1.25rem;
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  width: 80vw;
  height: 65vh;
  border-radius: 1rem;
}

.actionsCardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
