<template>
  <div class="content">
    <div class="content-container">
      <div class="header">
        <div class="header-container">
          <div class="logo">
            <img class="logo-img" src="../assets/logo.webp" alt="logo" />
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="page-card">
          <div class="welcome">
            <h1>Hello, {{ name }}!</h1>
          </div>
          <div class="buttons">
            <div class="important-btns">
              <router-link :to="{ path: '/report' }"
                ><button class="main-btn">
                  Submit A Bylaw Infraction
                </button></router-link
              >
              <router-link :to="{ path: '/manager/view-reports' }"
                ><button v-if="isAdmin || isManager" class="main-btn">
                  View All Reports
                </button></router-link
              >
              <!-- <router-link :to="{ path: '/report' }"
                ><button class="main-btn">
                  Submit A Maintenance Request
                </button></router-link
              > -->
            </div>
            <div class="secondary-btns">
              <!-- <button class="main-btn">Settings</button> -->
              <router-link :to="{ path: '/admin' }"
                ><button v-if="isAdmin" class="main-btn">
                  Admin Page
                </button></router-link
              >
              <router-link :to="{ path: '/reports/bylaw' }"
                ><button class="main-btn">
                  View Your Reports
                </button></router-link
              >
              <button class="main-btn" @click="logout">Log out</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      name: "",
      domain: process.env.VUE_APP_DOMAIN,
      isAdmin: false,
      isManager: false,
    };
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    logout() {
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");

      this.$router.push("/login");
    },
    fetchUserData() {
      const token = localStorage.getItem("userToken");
      if (token) {
        axios
          .post(`${this.domain}/api/user/name`, { token })
          .then((response) => {
            console.log(response);

            if (response.data.manager) {
              this.isManager = response.data.manager;
            } else if (response.data.admin) {
              this.isAdmin = response.data.admin;
            }

            this.name = response.data.name;
          })
          .catch((error) => {
            console.error("Error fetching user data:", error);
          });
      }
    },
  },
};
</script>

<style>
.welcome h1 {
  text-align: left;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.secondary-btns {
  margin-top: 2rem;
}
</style>
