<template>
  <div class="root">
    <div class="login-card">
      <div class="logo-container">
        <img src="../assets/logo.png" alt="CS Enforcer Logo" />
      </div>

      <form class="login-card-container" @submit.prevent="login">
        <div class="login-inputs">
          <input name="email" v-model="user.email" placeholder="Email" />
          <input
            name="password"
            v-model="user.password"
            placeholder="Password"
            type="password"
          />
        </div>

        <div class="login-errors">
          <span class="login-error" v-if="errorLogin">{{ errorLogin }}</span>
        </div>

        <button class="main-btn">Login</button>
        <router-link :to="{ path: '/register' }"
          ><p class="hyperlink">
            <i>Not yet registered? Register!</i>
          </p></router-link
        >
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      user: {
        email: "",
        password: "",
      },
      errorLogin: "",
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(
          `${this.domain}/api/login`,
          this.user
        );

        if (response.data == "Incorrect Email and/or Password!") {
          this.errorLogin = "Incorrect Email and/or Password!";
        } else {
          localStorage.setItem("userToken", response.data.token);
          this.$router.push("/home");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style>
.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}

.logo-container img {
  height: 6rem;
}

.login-card-container {
  text-align: center;
}

.login-card-container p {
  margin-top: 1rem;
}

.login-inputs {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-inputs input {
  padding: 1rem;
  border: 1px solid rgb(217, 217, 255);
  margin-bottom: 0.25rem;
}

.login-inputs input:focus {
  border: 1px solid var(--csm-blue);
}

.login-inputs input:first-child {
  border-radius: 1rem 1rem 0rem 0rem;
  margin-bottom: 0.25rem;
}

.login-inputs input:last-child {
  border-radius: 0rem 0rem 1rem 1rem;
  margin-bottom: 0;
}

.hyperlink {
  color: var(--csm-blue);
  transition: 0s;
  font-size: 1rem;
  font-weight: 100;
  text-decoration: underline;
}

.hyperlink:hover {
  color: var(--csm-blue-hover);
}

.login-errors {
  margin-top: 1rem;
}

.login-error {
  color: red;
  margin-bottom: 5rem;
  font-size: 0.8rem;
}
</style>
