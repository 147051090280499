<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="export-card" v-if="reportExport">
      <div class="export-card-content">
        <div class="report-dates">
          <div class="report-dates-container">
            <label>From</label>
            <input v-model="fromDate" type="date" />
          </div>
          <div class="report-dates-container">
            <label>To</label>
            <input v-model="toDate" type="date" />
          </div>
        </div>
        <select name="properties" v-model="exportProperty">
          <option
            v-for="property in properties"
            :key="property.property"
            :value="property.property"
          >
            {{ property.property }}
          </option>
        </select>

        <button class="main-btn" @click="beginExport">Export</button>
        <button
          class="del-btn delete-btn"
          style="margin-left: 0"
          @click="unexportData"
        >
          Close
        </button>
      </div>
    </div>

    <!-- <div class="exportTable" v-if="exportingReports"></div> -->
    <div class="exportTable" v-if="exportingReports">
      <div class="exportTableContainer">
        <ExportTable
          :data="approvedExports"
          @close-export="exportingReports = false"
          @start-export="beginExporting"
        />
      </div>
    </div>

    <div class="content-container">
      <div class="header">
        <div class="header-container">
          <div class="logo">
            <img class="logo-img" src="../assets/logo.png" />
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="page-card">
          <div class="card-header">
            <h1>Infraction Reports</h1>
            <div>
              <router-link :to="{ path: '/home' }">
                <a class="back-btn">
                  <i class="fa-solid fa-arrow-left"></i>
                  <p style="margin-left: 0.25rem">Back</p>
                </a>
              </router-link>
            </div>
          </div>

          <div class="report-type">
            <router-link :to="{ path: '/manager/view-reports/' }">
              <button class="button-left">Unapproved Reports</button>
            </router-link>
            <router-link :to="{ path: '/manager/view-reports/approved' }">
              <button class="button-right active">Approved Reports</button>
            </router-link>
          </div>

          <div class="report-archive">
            <button @click="startReportExport()" class="approve-btn">
              Export Reports
            </button>
          </div>

          <div class="reports">
            <table>
              <tbody>
                <tr v-for="report in sortedReports" :key="report.id">
                  <td>
                    <span>{{
                      report.property +
                      " | " +
                      report.unit +
                      " | " +
                      report.date
                    }}</span>
                    <button @click="viewReport(report.id)" class="view-btn">
                      View Report
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import ExportTable from "../components/ExportTable.vue";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      reports: [], // Array to store reports
      reportExport: false,
      exportingReports: false,
      approvedExports: [],
      properties: [],
      fromDate: "",
      toDate: "",
      exportProperty: "",
    };
  },
  components: {
    ExportTable,
  },
  async mounted() {
    this.fetchReports();
    try {
      const response = await axios.get(`${this.domain}/api/properties`);
      const data = await response;
      this.properties = data.data;
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  },
  computed: {
    sortedReports() {
      return this.reports.slice().sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    },
  },
  methods: {
    fetchReports() {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        axios
          .post(`${this.domain}/api/manager/reports/approved`, { userToken })
          .then((response) => {
            this.reports = response.data.reports;
          })
          .catch((error) => {
            console.error("Error fetching reports:", error);
          });
      } else {
        console.error("No user token found");
        // Handle the absence of token, e.g., redirect to login
      }
    },
    viewReport(reportId) {
      console.log("Viewing report:", reportId);
      this.$router.push(`/manager/approved/${reportId}`);
    },
    startReportExport() {
      this.reportExport = true;
    },
    beginExport() {
      const userToken = localStorage.getItem("userToken");
      const property = this.exportProperty;
      const from = this.fromDate;
      const to = this.toDate;

      axios
        .get(`${this.domain}/api/manager/reports/exporting`, {
          params: { userToken, property, from, to },
        })
        .then((response) => {
          this.exportingReports = true;
          this.reportExport = false;
          this.approvedExports = response.data;
        });
    },
    exportData() {
      const userToken = localStorage.getItem("userToken");
      const property = this.exportProperty;
      const from = this.fromDate;
      const to = this.toDate;

      axios
        .get(`${this.domain}/api/manager/reports/export`, {
          params: { userToken, property, from, to }, // Sending query parameters
          responseType: "blob",
        })
        .then((response) => {
          console.log(response.data);
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const downloadLink = document.createElement("a");
          downloadLink.href = window.URL.createObjectURL(pdfBlob);
          downloadLink.download = "output.pdf";
          downloadLink.click();
        });

      console.log(to, from, property, userToken);
    },
    beginExporting(checkedItems) {
      const userToken = localStorage.getItem("userToken");
      const data = checkedItems;

      axios
        .post(
          `${this.domain}/api/manager/reports/export/pdf`,
          {
            userToken: userToken,
            data: data,
          },
          {
            responseType: "arraybuffer", // Set responseType to 'arraybuffer' to receive binary data
          }
        )
        .then((response) => {
          const fileName = `${this.fromDate} to ${this.toDate} - ${this.exportProperty} Export`;

          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });

          const blobURL = window.URL.createObjectURL(pdfBlob);

          const downloadLink = document.createElement("a");
          downloadLink.href = blobURL;
          downloadLink.download = `${fileName}.pdf`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((error) => {
          console.error("Error exporting PDF:", error);
        });
    },
    unexportData() {
      this.reportExport = false;
    },
    exitExport() {
      console.log("Exiting export");
      this.exportingReports = false;
    },
  },
};
</script>

<style>
.report-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.welcome h1 {
  text-align: left;
}

.reports {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

th {
  background-color: #f2f2f2;
}

.view-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.view-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.active {
  background: rgb(14, 103, 155);
}

.report-archive {
  margin-top: 1rem;
}

.export-card {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  right: 0;
}

.export-card-content {
  background: white;
  color: black;
  font-size: 1.25rem;
  padding: 1rem;
  border-radius: 0.25rem;
  width: 25rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.report-dates {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.report-dates-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.report-dates-container label {
  margin-top: 0 !important;
}

.x-button {
  color: red;
}

.exportTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  background: rgba(0, 0, 0, 0.5);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.exportTableContainer {
  background: white;
  padding: 2rem;
  border-radius: 0.25rem;
}
</style>
