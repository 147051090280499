<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>
  <div class="tos-root">
    <div class="header">
      <div class="tos-header">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>

        <h1>Terms of Service</h1>

        <router-link :to="{ path: '/home' }"
          ><button class="home-btn">Home</button></router-link
        >
      </div>
    </div>

    <div class="tos-card-header">
      <h1 style="text-align: center; margin-bottom: 0.5rem">
        CS Enforcer Program Disclaimer
      </h1>

      <p style="text-align: center; margin-bottom: 0.75rem">
        By using the CS Enforcer program, you acknowledge that you have read,
        understood, and agree to be bound by these terms and conditions.
      </p>

      <p style="text-align: center">
        The use of the CS Enforcer program ("the Program") is strictly voluntary
        and at the sole discretion of the user. By utilizing the Program, you
        agree to the following terms:
      </p>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-handshake voluntary-use-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>Voluntary Use</h2>
        <p>
          The Program is intended for voluntary use only. Users are not
          obligated to utilize the Program and may opt-out at any time.
        </p>
      </div>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-exclamation-triangle no-guarantees-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>No Guarantees or Warranties</h2>
        <p>
          CS Management Inc. does not guarantee the accuracy, reliability, or
          completeness of the results obtained from the Program. The Program is
          provided "as is" without any warranties, express or implied.
        </p>
      </div>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-ban no-liability-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>No Liability</h2>
        <p>
          CS Management Inc. shall not be held liable for any inaccuracies,
          errors, or misuse of the Program. Users are solely responsible for
          their use of the Program and any consequences thereof.
        </p>
      </div>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-user-check user-responsibility-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>User Responsibility</h2>
        <p>
          Users are responsible for verifying the accuracy and validity of the
          results obtained from the Program before making any decisions based on
          such results.
        </p>
      </div>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-shield-alt indemnification-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>Indemnification</h2>
        <p>
          By using the Program, you agree to indemnify and hold harmless CS
          Management Inc., its officers, employees, and affiliates from any
          claims, damages, liabilities, or expenses arising out of or related to
          your use of the Program.
        </p>
      </div>
    </div>

    <div class="tos-card">
      <div class="tos-card-icon-container">
        <i class="fas fa-edit modification-termination-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>Modification and Termination</h2>
        <p>
          CS Management Inc. reserves the right to modify, suspend, or terminate
          the Program at any time without prior notice.
        </p>
      </div>
    </div>

    <div class="tos-card tos-card-end">
      <div class="tos-card-icon-container">
        <i class="fas fa-check-circle acceptance-of-terms-icon"></i>
      </div>
      <div class="tos-card-container">
        <h2>Acceptance of Terms</h2>
        <p>
          Your use of the Program constitutes your acceptance of these terms and
          conditions. If you do not agree with these terms, please refrain from
          using the Program.
        </p>
      </div>
    </div>
  </div>
</template>

<style>
.voluntary-use-icon {
  color: #007bff;
} /* Blue */
.no-guarantees-icon {
  color: #dc3545;
} /* Red */
.no-liability-icon {
  color: #ffc107;
} /* Yellow */
.user-responsibility-icon {
  color: #28a745;
} /* Green */
.indemnification-icon {
  color: #6610f2;
} /* Purple */
.modification-termination-icon {
  color: #17a2b8;
} /* Cyan */
.acceptance-of-terms-icon {
  color: #343a40;
} /* Dark */

.tos-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tos-header {
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns with equal width */ /* Adjust the gap between grid items */
  text-align: center;
  align-items: center;
  justify-items: center;
  grid-gap: 10px;
  width: 100%;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Three columns with equal width */
  grid-gap: 10px; /* Adjust the gap between grid items */
}

.header {
  width: 100%;
}

.home-btn {
  padding: 1rem 2.5rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  background: rgb(3, 94, 139);
  color: white;
  transition: 0.2s;
}

.home-btn:hover {
  box-shadow: inset 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: inset 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.home-btn:active {
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.tos-card {
  background: white;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 5rem 2fr; /* One part for square, two parts for rectangle */
  gap: 1rem; /* Gap between the items */
  width: 40rem;
  border-radius: 0.5rem;
  margin-top: 1rem;

  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.tos-card-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 3.5rem;
}

.tos-card-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}

.tos-card-end {
  margin-bottom: 4rem;
}

.tos-card-header {
  background: rgb(248, 171, 171);
  padding: 1.5rem;
  width: 40rem;
  border-radius: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  border: 2.5px solid red;

  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}
</style>
