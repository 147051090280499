<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="details-card">
        <div class="card-header">
          <div style="display: flex; flex-direction: row; align-items: center">
            <h1>Past Report</h1>
          </div>
          <div>
            <router-link :to="{ path: '/reports/bylaw' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <div class="download-container">
          <button class="main-btn download-btn" @click="downloadReport">
            Download Report
            <i class="fa-solid fa-file-arrow-down"></i>
          </button>

          <!-- <button class="main-btn download-btn" @click="uploadVideo()">
            Upload Video
            <i class="fa-solid fa-upload"></i>
          </button> -->
        </div>

        <div v-if="report">
          <table border="1">
            <tr>
              <td><strong>Property:</strong></td>
              <td>{{ report.property }}</td>
            </tr>
            <tr>
              <td><strong>Infraction:</strong></td>
              <td>{{ report.infraction }}</td>
            </tr>
            <tr>
              <td><strong>Unit:</strong></td>
              <td>{{ report.unit === "null" ? "Unknown" : report.unit }}</td>
            </tr>
            <tr>
              <td><strong>Proposed Sanction:</strong></td>
              <td>{{ report.sanction }}</td>
            </tr>
            <tr>
              <td><strong>Offense Date:</strong></td>
              <td>{{ report.date }}</td>
            </tr>
            <tr>
              <td><strong>Compliance Date:</strong></td>
              <td>{{ report.compliance }}</td>
            </tr>
            <tr>
              <td><strong>Comments:</strong></td>
              <td>
                {{ report.comments === "null" ? "None" : report.comments }}
              </td>
            </tr>
          </table>
        </div>

        <div class="images-header">
          <h3>Attached Files</h3>
        </div>

        <div class="images-container" v-if="images.length">
          <template v-for="mediaItem in images" :key="mediaItem">
            <!-- Render an img tag for images -->
            <img
              v-if="isImage(mediaItem)"
              :src="mediaItem"
              alt="Report Image"
              class="report-media"
            />
            <!-- Render a video tag for videos -->
            <video
              v-else-if="isVideo(mediaItem)"
              controls
              class="report-media"
              :src="mediaItem"
              :type="getVideoMimeType(mediaItem)"
            >
              Your browser does not support the video tag.
            </video>
          </template>
        </div>

        <p v-else>No report data available</p>
      </div>

      <!-- <div class="video-upload">
        <div class="video-upload-content">
          <p>Please select your file</p>
          <input type="file" />
          <button class="main-btn">Upload</button>
          <div class="x-button">X</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      report: null,
      images: [],
      uploadingVideo: false,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchReportDetails(this.id);
  },
  methods: {
    fetchReportDetails(reportId) {
      const userToken = localStorage.getItem("userToken");
      if (userToken && reportId) {
        axios
          .post(`${this.domain}/api/reportDetails`, { reportId, userToken })
          .then((response) => {
            this.report = response.data.report;
            this.images = response.data.images;
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
          });
      } else {
        console.error("No user token or report ID found");
      }
    },
    isImage(mediaItem) {
      return /\.(jpg|jpeg|png|gif)$/i.test(mediaItem);
    },
    isVideo(mediaItem) {
      return /\.(mp4|webm|ogg|avi|mov|mkv|flv|m4v|wmv)$/i.test(mediaItem);
    },

    getVideoMimeType(url) {
      if (/\.mp4$/i.test(url)) return "video/mp4";
      if (/\.webm$/i.test(url)) return "video/webm";
      if (/\.ogg$/i.test(url)) return "video/ogg";
      if (/\.avi$/i.test(url)) return "video/x-msvideo";
      if (/\.mov$/i.test(url)) return "video/quicktime";
      if (/\.mkv$/i.test(url)) return "video/x-matroska";
      if (/\.flv$/i.test(url)) return "video/x-flv";
      if (/\.m4v$/i.test(url)) return "video/x-m4v";
      if (/\.wmv$/i.test(url)) return "video/x-ms-wmv";
      return "application/octet-stream"; // Generic binary stream MIME type for unknown video formats
    },
    async downloadReport() {
      const reportId = this.id;
      const userToken = localStorage.getItem("userToken");
      console.log("downloading", reportId);
      try {
        axios
          .get(`${this.domain}/api/report/download`, {
            params: { reportId, userToken }, // Sending query parameters
            responseType: "blob", // Ensure response is treated as a blob
          })
          .then((response) => {
            console.log(response.data);
            const pdfBlob = new Blob([response.data], {
              type: "application/pdf",
            });
            const downloadLink = document.createElement("a");
            downloadLink.href = window.URL.createObjectURL(pdfBlob);
            downloadLink.download = "output.pdf";
            downloadLink.click();
          });
      } catch (error) {
        console.error("Error downloading PDF:", error);
      }
    },
    async uploadVideo() {
      console.log("video uploaded");
    },
  },
};
</script>

<style>
.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 4px dashed black;
  padding: 1rem;
}

.images-header {
  margin: 1rem 0rem;
}

.download-btn {
  font-size: 1rem;
  padding: 0.5rem;
  width: 14rem;
  margin-top: 0.5rem;
}

.download-btn:hover {
  cursor: pointer;
}

.download-btn i {
  margin-left: 0.25rem;
}

.download-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.video-upload {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  right: 0;
}

.video-upload-content {
  background: white;
  color: black;
  font-size: 1.25rem;
  padding: 2rem;
  border-radius: 0.25rem;
  width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.video-upload-content p {
  margin-bottom: 1rem;
}
</style>
