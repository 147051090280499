<template lang="en">
  <router-view></router-view>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
* {
  border: none;
  margin: 0;
  padding: 0;

  font-family: "Open Sans", sans-serif;

  --csm-blue: #0081c3;
  --csm-blue-hover: #024f75;
  --csm-blue-active: #0484c4;

  transition: 0.2s;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
}

/* Phone Style */
@media only screen and (max-width: 600px) {
  .header {
    background: var(--csm-blue);
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .admin-page {
    padding: 1rem;
  }

  .details-card {
    background: white;
    padding: 2rem;
    width: 20rem;
    /* height: 30rem; */
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .page-card {
    background: white;
    padding: 2rem;
    width: 18rem;
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .login-card {
    background: white;
    padding: 2rem;
    width: 18rem;
    border-radius: 0.5rem;
    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .report-card {
    background: white;
    padding: 2rem;
    width: 20rem;
    border-radius: 0.5rem;
    margin-top: 4rem;
    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }
}

/* Tablet Style */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
  .header {
    background: var(--csm-blue);
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .page-card {
    background: white;
    padding: 2rem;
    width: 30rem;
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .login-card {
    background: white;
    padding: 2rem;
    width: 20rem;
    border-radius: 0.5rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .report-card {
    background: white;
    padding: 2rem;
    width: 30rem;
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }
}

/* Desktop Style */
@media only screen and (min-width: 1025px) {
  .header {
    background: var(--csm-blue);
    height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  .details-card {
    background: white;
    padding: 2rem;
    width: 30rem;
    /* height: 30rem; */
    border-radius: 0.5rem;
    margin-top: 4rem;
    margin-bottom: 1rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .login-card {
    background: white;
    padding: 2rem;
    width: 20rem;
    border-radius: 0.5rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .page-card {
    background: white;
    padding: 2rem;
    width: 30rem;
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }

  .report-card {
    background: white;
    padding: 2rem;
    width: 30rem;
    border-radius: 0.5rem;
    margin-top: 4rem;

    box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  }
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.page-content {
  height: calc(100vh - 10vh);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo-img {
  height: 4rem;
}

.main-btn {
  margin-top: 1rem;
  padding: 1rem 0rem;
  width: 100%;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  background: var(--csm-blue);
}

.main-btn:hover {
  background: var(--csm-blue-hover);
  cursor: pointer;
}

.main-btn:active {
  background-color: var(--csm-blue-active);
  transition: 0.1s;
}

.back-btn {
  color: black;
}

.del-btn {
  margin-top: 1rem;
  padding: 0.5rem 0rem;
  width: 100%;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  background: var(--csm-blue);
}
</style>
