<template>
  <div class="content">
    <div class="content-container">
      <div class="header">
        <div class="header-container">
          <div class="logo">
            <img class="logo-img" src="../assets/logo.webp" alt="logo" />
          </div>
        </div>
      </div>

      <div class="admin-page-container">
        <div class="admin-page">
          <h1>Admin Controls</h1>
          <router-link :to="{ path: '/home' }"
            ><button class="main-btn">Home</button></router-link
          >
          <br />
          <router-link :to="{ path: '/view-reports' }"
            ><button class="main-btn">View Reports</button></router-link
          >
          <router-link :to="{ path: '/add-property' }"
            ><button class="main-btn">Add a new property</button></router-link
          >
          <router-link :to="{ path: '/edit-property' }"
            ><button class="main-btn">Edit a property</button></router-link
          >
          <router-link :to="{ path: '/admin/users/view' }"
            ><button class="main-btn">Edit Users</button></router-link
          >
          <router-link :to="{ path: '/admin/actions' }"
            ><button class="main-btn">Action Log</button></router-link
          >
          <router-link :to="{ path: '/reset-password' }"
            ><button class="main-btn">Reset Password</button></router-link
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* import axios from 'axios'; */

export default {
  data() {
    return {
      name: "",
    };
  },
  created() {
    this.fetchUserData();
  },
  methods: {
    logout() {
      // Clear userToken and userId from localStorage
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");

      // Redirect to login page
      this.$router.push("/login");
    },
    fetchUserData() {
      console.log("admin logic here");
      /* const userId = localStorage.getItem('userId');
      if (userId) {
        axios.post('https://csenforcer.ca/api/userName', { userId })
          .then(response => {
            this.name = response.data.name; // Assuming the response has a 'name' field
          })
          .catch(error => {
            console.error('Error fetching user data:', error);
          });
      } */
    },
  },
};
</script>

<style>
.welcome h1 {
  text-align: left;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.secondary-btns {
  margin-top: 2rem;
}
.admin-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.admin-page {
  width: 40rem;
  padding: 1rem;
}
</style>
