<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div class="content-container">
      <div class="header">
        <div class="header-container">
          <div class="logo">
            <img class="logo-img" src="../assets/logo.png" />
          </div>
        </div>
      </div>

      <div class="page-content">
        <div class="page-card">
          <div class="card-header">
            <h1>Past Reports</h1>
            <div>
              <router-link :to="{ path: '/home' }">
                <a class="back-btn">
                  <i class="fa-solid fa-arrow-left"></i>
                  <p style="margin-left: 0.25rem">Back</p>
                </a>
              </router-link>
            </div>
          </div>

          <div class="report-type">
            <button class="option-btn active">Bylaw Reports</button>
            <button class="option-btn">Property Reports</button>
          </div>

          <div class="reports">
            <table>
              <tbody>
                <tr v-for="report in sortedReports" :key="report.id">
                  <td>
                    <span>{{
                      report.property +
                      " | " +
                      report.unit +
                      " | " +
                      report.date
                    }}</span>
                    <button @click="viewReport(report.id)" class="view-btn">
                      View Report
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      reports: [], // Array to store reports
    };
  },
  computed: {
    sortedReports() {
      return this.reports.slice().sort((a, b) => {
        return new Date(b.date) - new Date(a.date);
      });
    },
  },

  mounted() {
    this.fetchReports();
  },
  methods: {
    fetchReports() {
      const userToken = localStorage.getItem("userToken");
      if (userToken) {
        axios
          .post(`${this.domain}/api/reports`, { userToken })
          .then((response) => {
            // Assuming the response has a 'reports' field containing an array of reports
            this.reports = response.data.reports;
          })
          .catch((error) => {
            console.error("Error fetching reports:", error);
          });
      } else {
        console.error("No user token found");
        // Handle the absence of token, e.g., redirect to login
      }
    },
    viewReport(reportId) {
      console.log("Viewing report:", reportId);
      this.$router.push(`/report/${reportId}`);
    },
  },
};
</script>

<style>
.report-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.welcome h1 {
  text-align: left;
}

.reports {
  display: flex;
  flex-direction: column;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

td {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

th {
  background-color: #f2f2f2;
}

.view-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.view-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.option-btn {
  margin-top: 1rem;
  padding: 1rem 0rem;
  width: 100%;
  border-radius: 0.25rem;
  color: white;
  font-size: 1rem;
  background: var(--csm-blue);
}

.option-btn:first-child {
  border-radius: 1rem 0rem 0rem 1rem;
}

.option-btn:last-child {
  border-radius: 0rem 1rem 1rem 0rem;
}

.option-btn:hover {
  background: var(--csm-blue-hover);
  cursor: pointer;
}

/* .option-btn:active{
    background-color: var(--csm-blue-active);
    transition: 0.1s;
} */

.active {
  background: rgb(14, 103, 155);
}
</style>
