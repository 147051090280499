<template>
  <head>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css"
    />
  </head>

  <div class="content">
    <div v-if="sendingEmail" class="email-card">
      <div v-if="sendingEmailData" class="email-card-content">
        <p>Sending Email</p>
        <div class="lds-roller2">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <div v-if="emailSent" class="email-card-content">
        <p>{{ emailResponse }}</p>
        <button @click="closeEmailResponse()" class="main-btn">OK</button>
      </div>
    </div>

    <div class="header">
      <div class="header-container">
        <div class="logo">
          <img class="logo-img" src="../assets/logo.png" />
        </div>
      </div>
    </div>
    <div class="page-content">
      <div class="details-card">
        <div class="card-header">
          <div style="display: flex; flex-direction: row; align-items: center">
            <h1>Report {{ this.id }}</h1>
          </div>
          <div>
            <router-link :to="{ path: '/manager/view-reports' }">
              <a class="back-btn">
                <i class="fa-solid fa-arrow-left"></i>
                <p style="margin-left: 0.25rem">Back</p>
              </a>
            </router-link>
          </div>
        </div>

        <div v-if="report">
          <table border="1">
            <tr>
              <td>
                <strong>Property:</strong
                ><button
                  v-if="!editingProperty"
                  @click="editProperty()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingProperty"
                  @click="submitProperty(report.property)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <p v-if="!editingProperty">{{ report.property }}</p>

                <select
                  v-if="editingProperty"
                  v-model="report.property"
                  name="properties"
                >
                  <option
                    v-for="property in properties"
                    :key="property.id"
                    :value="property.property"
                  >
                    {{ property.property }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Infraction:</strong
                ><button
                  v-if="!editingInfraction"
                  @click="editInfraction()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingInfraction"
                  @click="submitInfraction(report.infraction)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <p v-if="!editingInfraction">{{ report.infraction }}</p>

                <select
                  v-if="editingInfraction"
                  v-model="report.infraction"
                  name="bylaws"
                >
                  <option
                    v-for="bylaw in bylaws"
                    :key="bylaw.id"
                    :value="bylaw.bylaw_title"
                  >
                    {{ bylaw.bylaw_title }}
                  </option>
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Unit:</strong
                ><button
                  v-if="!editingUnit"
                  @click="editUnit()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingUnit"
                  @click="submitUnit(report.unit)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <input v-if="editingUnit" v-model="report.unit" />
                <p v-if="!editingUnit">
                  {{ report.unit === "null" ? "Unknown" : report.unit }}
                </p>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Proposed Sanction:</strong
                ><button
                  v-if="!editingSanction"
                  @click="editSanction()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingSanction"
                  @click="submitSanction(report.sanction)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <p v-if="!editingSanction">{{ report.sanction }}</p>

                <select
                  v-if="editingSanction"
                  v-model="report.sanction"
                  name="sanction"
                >
                  <option>$50</option>
                  <option>$100</option>
                  <option>$150</option>
                  <option>$200</option>
                  <option>$250</option>
                  <option>$300</option>
                  <option>$350</option>
                  <option>$400</option>
                  <option>$450</option>
                  <option>$500</option>
                  <option>$550</option>
                  <option>$600</option>
                  <option>$650</option>
                  <option>$700</option>
                  <option>$750</option>
                  <option>$800</option>
                  <option>$850</option>
                  <option>$900</option>
                  <option>$950</option>
                  <option>$1,000</option>
                </select>
              </td>
            </tr>

            <tr>
              <td>
                <strong>Offense Date:</strong
                ><button
                  v-if="!editingOffense"
                  @click="editOffense()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingOffense"
                  @click="submitOffense(report.date)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <input
                  v-if="editingOffense"
                  type="date"
                  v-model="report.date"
                />
                <p v-if="!editingOffense">
                  {{ report.date }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Compliance Date:</strong
                ><button
                  v-if="!editingCompliance"
                  @click="editCompliance()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-if="editingCompliance"
                  @click="submitCompliance(report.compliance)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <input
                  v-if="editingCompliance"
                  type="date"
                  v-model="report.compliance"
                />
                <p v-if="!editingCompliance">
                  {{ report.compliance }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Comments:</strong
                ><button
                  v-if="!editingComment"
                  @click="editComment()"
                  class="edit-btn"
                >
                  Edit
                </button>
                <button
                  v-else
                  @click="submitComment(report.comments)"
                  class="edit-btn"
                >
                  Submit
                </button>
              </td>
              <td>
                <textarea
                  class="commentArea"
                  v-if="editingComment"
                  v-model="report.comments"
                />
                <p v-if="!editingComment">
                  {{ report.comments === "null" ? "None" : report.comments }}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <strong>Reporter Email:</strong>
              </td>
              <td>
                {{ report.email }}
              </td>
            </tr>
            <tr>
              <td>
                <strong>Offenders Email:</strong>
              </td>
              <td>
                <input
                  class="offender-email"
                  v-model="offEmail"
                  placeholder="email@address.tld"
                />
                <button class="edit-btn" @click="sendEmail(offEmail)">
                  Send Email
                </button>
              </td>
              <td>
                <strong>Approve Report</strong>
              </td>
              <td>
                <button class="approve-btn" @click="approveReport(this.id)">
                  Approve Report
                </button>
              </td>
              <td>
                <button
                  class="approve-btn delete-btn"
                  @click="deleteReport(this.id)"
                  style="margin: 0"
                >
                  Delete Report
                </button>
              </td>
            </tr>
          </table>
        </div>

        <div class="images-header">
          <h3>Images</h3>
        </div>

        <div class="images-container" v-if="images.length">
          <template v-for="mediaItem in images" :key="mediaItem">
            <img
              v-if="isImage(mediaItem)"
              :src="'../' + mediaItem"
              alt="Report Image"
              class="report-media"
            />
            <video
              v-else-if="isVideo(mediaItem)"
              controls
              class="report-media"
              :src="'../' + mediaItem"
              :type="getVideoMimeType(mediaItem)"
            >
              Your browser does not support the video tag.
            </video>
          </template>
        </div>

        <p v-else>No report data available</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      domain: process.env.VUE_APP_DOMAIN,
      report: null,
      images: [],
      editingProperty: false,
      editingInfraction: false,
      editingUnit: false,
      editingSanction: false,
      editingOffense: false,
      editingCompliance: false,
      editingComment: false,
      sendingEmail: false,
      sendingEmailData: false,
      emailSent: false,
      emailResponse: "",
      properties: [],
      bylaws: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.fetchReportDetails(this.id);
    this.fetchProperties();
  },
  methods: {
    fetchReportDetails(reportId) {
      const userToken = localStorage.getItem("userToken");
      if (userToken && reportId) {
        axios
          .post(`${this.domain}/api/admin/reportDetails`, {
            reportId,
            userToken,
          })
          .then((response) => {
            this.report = response.data.report;
            this.images = response.data.images;
          })
          .catch((error) => {
            console.error("Error fetching report details:", error);
          });
      } else {
        console.error("No user token or report ID found");
      }
    },
    isImage(mediaItem) {
      return /\.(jpg|jpeg|png|gif)$/i.test(mediaItem);
    },
    isVideo(mediaItem) {
      return /\.(mp4|webm|ogg|avi|mov|mkv|flv|m4v|wmv)$/i.test(mediaItem);
    },

    getVideoMimeType(url) {
      if (/\.mp4$/i.test(url)) return "video/mp4";
      if (/\.webm$/i.test(url)) return "video/webm";
      if (/\.ogg$/i.test(url)) return "video/ogg";
      if (/\.avi$/i.test(url)) return "video/x-msvideo";
      if (/\.mov$/i.test(url)) return "video/quicktime";
      if (/\.mkv$/i.test(url)) return "video/x-matroska";
      if (/\.flv$/i.test(url)) return "video/x-flv";
      if (/\.m4v$/i.test(url)) return "video/x-m4v";
      if (/\.wmv$/i.test(url)) return "video/x-ms-wmv";
      return "application/octet-stream"; // Generic binary stream MIME type for unknown video formats
    },
    fetchProperties() {
      axios.get(`${this.domain}/api/properties`).then((res) => {
        this.properties = res.data;
      });
    },
    editComment() {
      this.editingComment = true;
    },
    editProperty() {
      this.editingProperty = true;
    },
    editInfraction() {
      this.editingInfraction = true;
      const userToken = localStorage.getItem("userToken");
      const propertyName = this.report.property;
      axios
        .post(`${this.domain}/api/admin/bylaws`, {
          userToken,
          propertyName,
        })
        .then((res) => {
          this.bylaws = res.data.bylaws;
        });
    },
    editUnit() {
      this.editingUnit = true;
    },
    editSanction() {
      this.editingSanction = true;
    },
    editOffense() {
      this.editingOffense = true;
    },
    editCompliance() {
      this.editingCompliance = true;
    },
    closeEmailResponse() {
      this.sendingEmail = false;
      this.emailSent = false;
    },
    submitComment(comment) {
      this.editingComment = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/comments`, {
        reportId,
        userToken,
        comment,
      });
    },
    submitProperty(property) {
      this.editingProperty = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      for (let i = 0; i < this.properties.length; i++) {
        if (this.properties[i].property === property) {
          const propertyName = this.properties[i].property;
          this.report.property = propertyName;
          axios.post(`${this.domain}/api/admin/report/edit/property`, {
            reportId,
            userToken,
            propertyName,
          });
        }
      }
    },
    submitInfraction(infraction) {
      this.editingInfraction = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/infraction`, {
        reportId,
        userToken,
        infraction,
      });
    },
    submitUnit(unit) {
      this.editingUnit = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/unit`, {
        reportId,
        userToken,
        unit,
      });
    },
    submitSanction(sanction) {
      this.editingSanction = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/sanction`, {
        reportId,
        userToken,
        sanction,
      });
    },
    submitOffense(offense) {
      this.editingOffense = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/offense`, {
        reportId,
        userToken,
        offense,
      });
    },
    submitCompliance(compliance) {
      this.editingCompliance = false;
      const reportId = this.report.id;
      const userToken = localStorage.getItem("userToken");
      axios.post(`${this.domain}/api/admin/report/edit/compliance`, {
        reportId,
        userToken,
        compliance,
      });
    },
    sendEmail(offEmail) {
      const email = offEmail;
      const report = this.report;
      const userToken = localStorage.getItem("userToken");

      if (this.sendingEmail == false) {
        this.sendingEmail = true;
        this.sendingEmailData = true;
        axios
          .post(`${this.domain}/api/admin/report/email`, {
            userToken,
            email,
            report,
          })
          .then((response) => {
            this.emailSent = true;
            this.sendingEmailData = false;
            this.emailResponse = response.data.message;
            console.log(response.data.message);
          });
      }
    },
    approveReport(reportId) {
      const userToken = localStorage.getItem("userToken");
      axios
        .post(`${this.domain}/api/admin/report/approve`, {
          userToken,
          reportId,
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$router.push(`/manager/view-reports`);
          }
        });
    },
    deleteReport(reportId) {
      const userToken = localStorage.getItem("userToken");
      axios
        .post(`${this.domain}/api/admin/report/delete`, {
          userToken,
          reportId,
        })
        .then((response) => {
          if (response.data.success == true) {
            this.$router.push(`/manager/view-reports`);
          }
        });
    },
  },
};
</script>

<style>
.images-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border: 4px dashed black;
  padding: 1rem;
}

.images-header {
  margin: 1rem 0rem;
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.25rem 0.75rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.edit-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.plus-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.25rem 0.3rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.plus-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.plus-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}
.commentArea {
  width: 100%;
}

select {
  width: 100%;
}

.offender-email {
  width: 75%;
}

.approve-btn {
  background-color: var(--csm-blue);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
}

.approve-btn:hover {
  background: #00689c;
  cursor: pointer;
}

.approve-btn:active {
  background-color: #0484c4;
  transition: 0.1s;
}

.lds-roller2 {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller2 div {
  animation: lds-roller2 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller2 div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: rgb(48, 48, 48);
  margin: -4px 0 0 -4px;
}
.lds-roller2 div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller2 div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller2 div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller2 div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller2 div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller2 div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller2 div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller2 div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller2 div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller2 div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller2 div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller2 div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller2 div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller2 div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller2 div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller2 div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.email-card {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.25);
  position: fixed;
  color: white;
  top: 0;
  left: 0;
  right: 0;
}

.email-card-content {
  background: white;
  color: black;
  font-size: 1.25rem;
  padding: 2rem;
  border-radius: 0.25rem;
  width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 21px -4px rgba(0, 0, 0, 0.5);
}

.email-card-content p {
  margin-bottom: 1rem;
}
</style>
